<template>
  <div class="discord"><app-header title="Discord Authorization Redirect Connect" :hide-back="true"/></div>
</template>

<script>
  import AppHeader from '../../components/AppHeader';
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    name: 'DiscordAuthorizeRedirectConnect',
    components: {
      AppHeader
    },
    mixins: [pageTitleMixin],
    created() {
      this.setPageTitle('Discord Authorization Redirect Connect');
      window.location = 'https://discord.com/api/oauth2/authorize?client_id=745240031349702719&redirect_uri=https%3A%2F%2Fhq.hyperion-corporation.de%2Fdiscord-handle-connect&response_type=code&scope=identify';
    }
  }
</script>
